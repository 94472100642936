<template>
  <div class="report-revenues" v-if="mayIsee">
    <b-container style="max-width: 92% !important">
      <b-row class="mb-3">
        <div>
          <b-input-group>
            <HotelDatePicker
              class="item-date-picker-revenue"
              format="DD-MM-YYYY"
              @confirm="customDateConfirmed()"
              v-model="customDate"
              type="date"
              :clearable="false"
              confirm
              range
              placeholder="Selecione o período"
            ></HotelDatePicker>
            <b-form-select
              class="revenue-selects"
              @change="selectionUpdated"
              v-model="baseSelected"
              :options="activeBases"
            ></b-form-select>
            <b-form-select
              class="revenue-selects"
              @change="selectionUpdated"
              v-model="eventSelected"
              :options="eventsToShow"
            ></b-form-select>
            <b-form-select
              class="revenue-selects"
              @change="selectionUpdated"
              v-model="companieSelected"
              :options="companiesToShow"
            ></b-form-select>
            <b-button
              type="button"
              variant="btn btn-primary"
              size="sm"
              @click="download"
              >Baixar arquivo</b-button
            >
          </b-input-group>
        </div>
      </b-row>
      <b-row v-if="baseSelected && !eventSelected && !sortedArray.length">
        <div class="card">
          <div class="table-responsive" style="max-height: 600px">
            <table
              id="table-to-export-general"
              class="table table-hover table-outline table-vcenter text-nowrap card-table"
            >
              <thead>
                <tr style="cursor: pointer">
                  <th class="fixed-top-revenue">TOTAL</th>
                  <th class="fixed-top-revenue">
                    {{
                      generalTotal.acessos
                        ? formatNumber.format(generalTotal.acessos)
                        : 0
                    }}
                  </th>
                  <th class="fixed-top-revenue">
                    {{
                      generalTotal.cadastros
                        ? formatNumber.format(generalTotal.cadastros)
                        : 0
                    }}
                  </th>
                  <th class="fixed-top-revenue">
                    {{
                      formatPercentage.format(generalTotal.percentageAcessos)
                    }}
                  </th>
                  <th class="fixed-top-revenue">
                    {{
                      generalTotal.pedidos
                        ? formatNumber.format(generalTotal.pedidos)
                        : 0
                    }}
                  </th>
                  <th class="fixed-top-revenue">
                    {{
                      formatPercentage.format(generalTotal.percentageAcessos2)
                    }}
                  </th>
                  <th class="fixed-top-revenue">
                    {{
                      formatPercentage.format(generalTotal.percentageCadastros)
                    }}
                  </th>
                  <th class="fixed-top-revenue">
                    {{
                      generalTotal.ingressos
                        ? formatNumber.format(generalTotal.ingressos)
                        : 0
                    }}
                  </th>
                  <th class="fixed-top-revenue">
                    {{
                      generalTotal.vendaTotal
                        ? currency.format(generalTotal.vendaTotal)
                        : "R$ 0,00"
                    }}
                  </th>
                  <th class="fixed-top-revenue">
                    {{
                      generalTotal.receitaTotal
                        ? currency.format(generalTotal.receitaTotal)
                        : "R$ 0,00"
                    }}
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="(label, idx) in genereralLabels"
                    :key="idx"
                    class="w-3"
                  >
                    {{ label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, idx) in generalData"
                  :key="idx"
                  style="cursor: pointer"
                >
                  <td class="text-muted">{{ data.dia }}</td>
                  <td>
                    {{
                      ["corporativo", "todos"].indexOf(baseSelected) > -1
                        ? formatNumber.format(data.acessos)
                        : 0
                    }}
                  </td>
                  <td class="clearfix">
                    {{
                      data.cadastros ? formatNumber.format(data.cadastros) : 0
                    }}
                  </td>
                  <td class="clearfix">
                    <div>
                      {{ formatPercentage.format(data.percentageAcessos) }}
                    </div>
                  </td>
                  <td class="clearfix">
                    {{ data.pedidos ? formatNumber.format(data.pedidos) : 0 }}
                  </td>
                  <td class="clearfix">
                    {{ formatPercentage.format(data.percentageAcessos2) }}
                  </td>
                  <td class="clearfix">
                    {{ formatPercentage.format(data.percentageCadastros) }}
                  </td>
                  <td class="clearfix">
                    {{
                      data.ingressos ? formatNumber.format(data.ingressos) : 0
                    }}
                  </td>
                  <td class="clearfix">
                    {{
                      data.vendaTotal
                        ? currency.format(data.vendaTotal)
                        : "R$ 0,00"
                    }}
                  </td>
                  <td class="clearfix">
                    {{
                      data.receitaTotal
                        ? currency.format(data.receitaTotal)
                        : "R$ 0,00"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-row>
      <b-row v-if="sortedArray.length">
        <div class="card">
          <div class="table-responsive" style="max-height: 600px">
            <table
              id="table-to-export-specific"
              class="table table-hover table-outline table-vcenter text-nowrap card-table"
            >
              <thead>
                <tr style="cursor: pointer">
                  <th class="fixed-top-revenue w-3">TOTAL</th>
                  <th v-if="companieSelected && !showingEventDayDetail" class="fixed-top-revenue w-3">
                    -
                  </th>
                  <th v-if="showDateOnEvent" class="fixed-top-revenue w-3">
                    -
                  </th>
                  <th
                    v-show="showingEventDayDetail"
                    class="fixed-top-revenue w-3"
                  >
                    -
                  </th>
                  <th
                    v-show="showingEventDayDetail"
                    class="fixed-top-revenue w-3"
                  >
                    -
                  </th>
                  <th
                    v-show="showingEventDayDetail"
                    class="fixed-top-revenue w-3"
                  >
                    -
                  </th>
                  <th
                    v-show="showingEventDayDetail"
                    class="fixed-top-revenue w-3"
                  >
                    -
                  </th>
                  <th
                    v-show="showingEventDayDetail"
                    class="fixed-top-revenue w-3"
                  >
                    -
                  </th>
                  <th
                    v-show="showingEventDayDetail"
                    class="fixed-top-revenue w-3"
                  >
                    -
                  </th>
                  <th class="fixed-top-revenue w-3">
                    {{
                      specificTotal.qtdeIngressos
                        ? formatNumber.format(specificTotal.qtdeIngressos)
                        : 0
                    }}
                  </th>
                  <th class="fixed-top-revenue w-3">
                    {{
                      specificTotal.vendaBruta
                        ? currency.format(specificTotal.vendaBruta)
                        : "R$ 0,00"
                    }}
                  </th>
                  <th class="fixed-top-revenue w-3">
                    {{
                      specificTotal.repasse
                        ? currency.format(specificTotal.repasse)
                        : "R$ 0,00"
                    }}
                  </th>
                  <th class="fixed-top-revenue w-3">
                    {{
                      specificTotal.receita
                        ? currency.format(specificTotal.receita)
                        : "R$ 0,00"
                    }}
                  </th>
                  <th class="fixed-top-revenue w-3">
                    {{ formatPercentage.format(specificTotal.margem) }}
                  </th>
                </tr>
                <tr>
                  <th
                    v-for="specificLabel in specificLabels.filter(
                      (item) => !item.hidden
                    )"
                    :key="specificLabel.label"
                    @click="
                      updateSortMethod(specificLabel);
                      sortMethod.sortOrder = !sortMethod.isToSort
                        ? 'none'
                        : sortMethod.sortOrder === 'asc'
                        ? 'desc'
                        : 'asc';
                    "
                    class="w-3"
                    style="user-select: none"
                    :style="{ cursor: specificLabel.isToSort ? 'pointer' : '' }"
                  >
                    {{ specificLabel.label }}
                    <span
                      v-if="specificLabel.isToSort"
                      style="position: absolute; margin-left: 4px"
                    >
                      <i
                        v-if="
                          sortMethod.sortOrder === 'asc' &&
                          specificLabel.label === sortMethod.label
                        "
                        class="fas fa-sort-alpha-up"
                      ></i>
                      <i
                        v-if="
                          sortMethod.sortOrder === 'desc' &&
                          specificLabel.label === sortMethod.label
                        "
                        class="fas fa-sort-alpha-down"
                      ></i>
                      <i
                        v-if="sortMethod.sortOrder === 'none'"
                        class="fas fa-sort"
                      ></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody :key="tableKey">
                <tr v-for="(data, idx) in sortedArray" :key="idx">
                  <td
                    v-for="(item, idx2) in Object.keys(data)"
                    :key="'items' +idx2 + idx"
                    :style="{
                      display: [
                        'dia',
                        'cliente',
                        'cpf',
                        'email',
                        'ddd',
                        'telefone',
                        'usuario',
                        'empresa',
                        'eventOrProduct',
                        'qtdeIngressos',
                        'vendaBruta',
                        'repasse',
                        'receita',
                        'margem',
                      ].includes(item)
                        ? 'table-cell'
                        : 'none',
                    }"
                    style="text-align: center"
                  >
                    <div
                      style="cursor: pointer"
                      class="fixedTableLabel"
                      @click="filterBySpecificQuery(data, 'evento')"
                      v-title="data[item]"
                      v-show="item === 'eventOrProduct'"
                    >
                      {{ data[item] }}
                    </div>
                    <div v-show="item === 'dia'">{{ data[item] }}</div>
                    <div v-show="item === 'cliente'">{{ data[item] }}</div>
                    <div v-show="item === 'cpf'">{{ data[item] }}</div>
                    <div v-show="item === 'email'">{{ data[item] }}</div>
                    <div v-show="item === 'ddd'">{{ data[item] }}</div>
                    <div v-show="item === 'telefone'">{{ data[item] }}</div>
                    <div v-show="item === 'usuario'">{{ data[item] }}</div>
                    <div
                      style="cursor: pointer"
                      class="fixedTableLabel"
                      v-show="item === 'empresa'"
                      @click="filterBySpecificQuery(data, 'empresa')"
                    >
                      {{ data[item] }}
                    </div>
                    <div v-show="item === 'qtdeIngressos'" class="clearfix">
                      {{ data[item] ? formatNumber.format(data[item]) : 0 }}
                    </div>
                    <div v-show="item === 'vendaBruta'">
                      <div>
                        {{ data[item] ? currency.format(data[item]) : "R$ 0" }}
                      </div>
                    </div>
                    <div v-show="item === 'repasse'" class="clearfix">
                      {{ data[item] ? currency.format(data[item]) : "R$ 0,00" }}
                    </div>
                    <div v-show="item === 'receita'" class="clearfix">
                      {{ data[item] ? currency.format(data[item]) : "R$ 0,00" }}
                    </div>
                    <div v-show="item === 'margem'" class="clearfix">
                      {{ formatPercentage.format(data[item]) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Vue from "vue";
import VueHead from "vue-head";
import HotelDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import { eventService } from "../../components/common/services/event";
import { userService } from "../../components/common/services/user";
import { revenueService } from "../../components/common/services/revenues";
import { dashboardService } from "../../components/common/services/dashboard";
import VTitle from "v-title";
import Moment from "moment";
import { VMoney } from "v-money";
import { extendMoment } from "moment-range";
import { func } from "@/functions";
const moment = extendMoment(Moment);
Vue.use(VTitle);
Vue.use(VueHead);
export default {
  mixins: [func],
  name: "report-partnersale",
  directives: {
    money: VMoney,
  },
  components: {
    HotelDatePicker,
  },
  head: {
    title: function () {
      return {
        inner: `TicketOffice | Admin`,
        separator: " | ",
        complement: "Relatórios - Venda por parceiro",
      };
    },
  },
  computed: {
    formatPercentage() {
      return {
        format: (numberToFormart, parseString = true) => {
          const value = numberToFormart
            ? (Math.floor(numberToFormart * 100) / 100)
                .toFixed(2)
                .replace(".", ",")
            : "0,00";
          return parseString ? `${value}%` : value;
        },
      };
    },
    currency() {
      return Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" });
    },
    formatNumber() {
      return Intl.NumberFormat("pt-BR", { maximumSignificantDigits: 10 });
    },
    eventsToShow() {
      const auxArray = this.specificEvents.length
        ? [...this.specificEvents]
        : [...this.filteredEvents];
      auxArray.unshift({
        value: "todos",
        text: "Todos os eventos",
      });
      auxArray.unshift({
        value: null,
        text: "Selecione um evento",
      });
      return auxArray;
    },
    companiesToShow() {
      return this.companies;
    },
    specificTotal() {
      const total = {
        qtdeIngressos: 0,
        vendaBruta: 0,
        repasse: 0,
        receita: 0,
        margem: 0,
        eventOrProduct: "TOTAL",
        dia: "TOTAL",
        empresa: "TOTAL",
      };
      this.sortedArray.forEach((item) => {
        total.qtdeIngressos += item.qtdeIngressos;
        total.vendaBruta += parseFloat(item.vendaBruta);
        total.repasse += item.repasse;
        total.receita += item.receita;
      });
      total.margem =
        total.receita > 0 && parseFloat(total.vendaBruta)
          ? (total.receita / total.vendaBruta) * 100
          : 0;
      return total;
    },
    sortedArray() {
  if (this.sortMethod.value === "default" || !this.sortMethod.isToSort) {
    return this.specificData;
  }

  const newArraySorted = [...this.specificData];

  return newArraySorted.sort((a, b) => {
    const sortValues = ["eventOrProduct", "dia", "empresa"];
    const indexA = sortValues.indexOf(this.sortMethod.value);
    const indexB = sortValues.indexOf(this.sortMethod.value);

    let aVal, bVal;

    if (indexA > -1) {
      aVal = a[this.sortMethod.value].trim().toLowerCase();
      bVal = b[this.sortMethod.value].trim().toLowerCase();
    } else {
      aVal = parseFloat(a[this.sortMethod.value]);
      bVal = parseFloat(b[this.sortMethod.value]);
    }

    if (this.sortMethod.sortOrder === "asc") {
      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
      return 0;
    } else if (this.sortMethod.sortOrder === "desc") {
      if (bVal < aVal) return -1;
      if (bVal > aVal) return 1;
      return 0;
    }
  });
},
    mayIsee() {
      return this.mayI("report-viewer", "report-accounting");
    },
  },
  created() {},
  async mounted() {
    this.showWaitAboveAll();
    const [eventos, bases, companies] = await Promise.all([
      eventService.list(this.getLoggedId(), "", 1, 5000),
      userService.baseSelect(this.getLoggedId()),
      dashboardService.getCompanies(this.getLoggedId()),
    ]);
    if (companies && companies.empresas) {
      this.companies = companies.empresas
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => ({ text: item.name, value: item.name.trim() }));
      this.companies.unshift({
        value: "todos",
        text: "Todas as empresas",
      });
      this.companies.unshift({
        value: null,
        text: "Selecione uma empresa",
      });
    }
    this.filteredEvents = this.getFilteredEvents(eventos)
      .map((event) => ({
        value: event.ds_evento.trim(),
        text: event.ds_evento,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
    this.activeBases = bases
      .map((base) => ({
        value: base.text,
        text: base.text,
        id: base.id_base,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
    this.activeBases.unshift({
      value: "todos",
      text: "Todas as bases",
    });
    this.activeBases.unshift({
      value: null,
      text: "Selecione uma base",
    });
    this.hideWaitAboveAll();
  },
  methods: {
    async download() {
      const tableSpecific = document.getElementById("table-to-export-specific");
      const tableGeneral = document.getElementById("table-to-export-general");
      if (!tableGeneral && !tableSpecific) return;
      if (
        !this.components.datepicker.startDate ||
        !this.components.datepicker.endDate
      )
        return false;
      // Array de objetos que será transformado em arquivo Excel
      const rows = [];
      if (tableSpecific) {
        let formatedTotal = { Evento: "" };
        let labels = [
          {
            column: "Evento",
            name: "eventOrProduct",
            formatter: null,
            default: "",
          },
          {
            column: "Qtde Ingressos",
            name: "qtdeIngressos",
            formatter: this.formatNumber,
            default: 0,
          },
          {
            column: "Venda Bruta",
            name: "vendaBruta",
            formatter: this.currency,
            default: "R$ 0,00",
          },
          {
            column: "Repasse",
            name: "repasse",
            formatter: this.currency,
            default: "R$ 0,00",
          },
          {
            column: "Receita",
            name: "receita",
            formatter: this.currency,
            default: "R$ 0,00",
          },
          {
            column: "Margem",
            name: "margem",
            formatter: this.formatPercentage,
            default: "0,00%",
          },
        ];

        if (this.companieSelected) {
          labels.unshift({
            column: "Empresa",
            name: "empresa",
            formatter: null,
            default: "",
          });
          labels.unshift({
            column: "Dia",
            name: "dia",
            formatter: null,
            default: "",
          });
          formatedTotal = {
            Dia: "",
            Empresa: "",
            Evento: "",
          };
        }

        if (this.showingEventDayDetail) {
          labels = [
            { column: "Dia", name: "dia", formatter: null, default: "" },
            {
              column: "Cliente",
              name: "cliente",
              formatter: null,
              default: "",
            },
            { column: "CPF", name: "cpf", formatter: null, default: "" },
            { column: "Email", name: "email", formatter: null, default: "" },
            { column: "DDD", name: "ddd", formatter: null, default: "" },
            {
              column: "Telefone",
              name: "telefone",
              formatter: null,
              default: "",
            },
            {
              column: "Usuario",
              name: "usuario",
              formatter: null,
              default: "",
            },
            ...labels,
          ].filter(label => label.name !== 'empresa');
          formatedTotal = {
            Dia: "",
            Cliente: "",
            CPF: "",
            Email: "",
            DDD: "",
            Telefone: "",
            Usuario: "",
            Evento: "",
          };
        }

        rows.push(formatedTotal);

        this.specificData.forEach((row) => {
          const aux = {};
          labels.forEach((label) => {
            const formatedLabel =
              label.formatter && row[label.name]
                ? label.formatter.format(row[label.name])
                : row[label.name];
            formatedTotal[label.column] = label.formatter
              ? label.formatter.format(this.specificTotal[label.name])
              : this.specificTotal[label.name];
            aux[label.column] = formatedLabel || label.default;
          });
          rows.push(aux);
        });
      }

      if (tableGeneral) {
        const formatedTotal = { Dia: "Total" };
        const labels = [
          { column: "Dia", name: "dia", formatter: null },
          {
            column: "Acessos",
            name: "acessos",
            formatter: this.formatNumber,
            default: 0,
          },
          {
            column: "Cadastros",
            name: "cadastros",
            formatter: this.formatNumber,
            default: 0,
          },
          {
            column: "% Acessos",
            name: "percentageAcessos",
            formatter: this.formatPercentage,
            default: "0,00%",
          },
          {
            column: "Pedidos",
            name: "pedidos",
            formatter: this.formatNumber,
            default: 0,
          },
          {
            column: "%  Acessos",
            name: "percentageAcessos2",
            formatter: this.formatPercentage,
            default: "0,00%",
          },
          {
            column: "% Cadastros",
            name: "percentageCadastros",
            formatter: this.formatPercentage,
            default: "0,00%",
          },
          {
            column: "Ingressos",
            name: "ingressos",
            formatter: this.formatNumber,
            default: 0,
          },
          {
            column: "Venda Total",
            name: "vendaTotal",
            formatter: this.currency,
            default: "R$ 0,00",
          },
          {
            column: "Receita Total",
            name: "receitaTotal",
            formatter: this.currency,
            default: "R$ 0,00",
          },
        ];

        rows.push(formatedTotal);

        this.generalData.forEach((row) => {
          const aux = {};
          labels.forEach((label) => {
            const formatedLabel =
              label.formatter && row[label.name]
                ? label.formatter.format(row[label.name])
                : row[label.name];
            formatedTotal[label.column] = label.formatter
              ? label.formatter.format(this.generalTotal[label.name])
              : this.generalTotal[label.name];
            aux[label.column] = formatedLabel || label.default;
          });
          rows.push(aux);
        });
      }

      // Cria um objeto WorkSheet
      const ws = XLSX.utils.json_to_sheet(rows);

      // Define a largura das colunas com base no tamanho do conteúdo
      const wscols = [];

      // Obtém as larguras das colunas dinamicamente
      rows.forEach((row) => {
        Object.keys(row).forEach((key) => {
          const columnData = row[key];
          const cellData = columnData ? columnData.toString() : "";
          const cellLength = cellData.length + 10;
          const columnName = key.replace(/\d+/g, ""); // Remove números do nome da coluna

          if (!wscols[columnName] || cellLength > wscols[columnName]) {
            wscols[columnName] = cellLength;
          }
        });
      });

      // Converte as larguras das colunas para o formato esperado pelo SheetJS
      Object.keys(wscols).forEach((columnName) => {
        const columnWidth = wscols[columnName];
        wscols.push({ wch: columnWidth });
      });

      ws["!cols"] = wscols;

      // Cria um objeto WorkBook e adiciona o WorkSheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Exporta para um arquivo
      XLSX.writeFile(wb, "relatorio_faturamento.xlsx");
    },
    getFilteredEvents(events) {
      return events.filter((evt) => evt.hasshowyet === 1);
    },
    updateSortMethod(itemToSort) {
      this.sortMethod = itemToSort;
    },
    formatOptionsToQuery() {
      const options = {
        ...(this.baseSelected && {
          base: this.baseSelected,
        }),
        ...(this.eventSelected && {
          event: this.eventSelected,
        }),
        ...(this.companieSelected && {
          empresa: this.companieSelected,
        }),
        dateStart: moment(this.components.datepicker.startDate).format(
          "YYYY-MM-DD"
        ),
        dateEnd: moment(this.components.datepicker.endDate).format(
          "YYYY-MM-DD"
        ),
        loggedId: this.getLoggedId(),
      };
      if (
        !this.components.datepicker.endDate ||
        (!this.baseSelected && !this.eventSelected && !this.companieSelected)
      ) {
        return false;
      }
      return options;
    },
    async filterBySpecificQuery(event, origin) {
      this.showWaitAboveAll();
      if (
        origin === "evento" &&
        this.showDateOnEvent &&
        this.eventSelected === event.eventOrProduct.trim()
      ) {
        this.showingEventDayDetail = true;
      }
      if (
        origin === "empresa" &&
        this.showDateOnEvent &&
        this.companieSelected === event.empresa.trim()
      ) {
        this.showingEventDayDetail = true;
      }
      this.showDateOnEvent = true;
      this.specificLabels[0].hidden = false;
      if (origin === "evento" && !this.showingEventDayDetail) {
        console.log("buscando evento");
        const hasThisEvent = this.filteredEvents.find(
          (evt) => evt.value === event.eventOrProduct.trim()
        );
        if (!hasThisEvent)
          this.filteredEvents.push({
            value: event.eventOrProduct.trim(),
            text: event.eventOrProduct,
          });
        this.$set(this, "eventSelected", event.eventOrProduct.trim());
      }
      if (origin === "empresa") {
        this.companieSelected = event.empresa.trim();
      }
      if (this.companieSelected && !this.showingEventDayDetail) {
        console.log("buscando empresa");
        return this.filterBySpecificCompanie();
      }
      const options = this.formatOptionsToQuery();
      if (!options) return this.hideWaitAboveAll();

      let res = {};
      if (this.showingEventDayDetail) {
        console.log("🦕 buscando dia detalhado", "🍒");
        options.dateStart = moment(event.dia).format("YYYY-MM-DD");
        options.dateEnd = options.dateStart;
        res = await revenueService.getDiaDetalhado(options);
        this.specificLabels.forEach((label) => {
          if (
            [
              "dia",
              "cliente",
              "cpf",
              "email",
              "ddd",
              "telefone",
              "usuario",
            ].includes(label.value)
          ) {
            label.hidden = false;
          }
          if (label.value === 'empresa') {
            label.hidden = true;
          }
        });
      } else {
        res = await revenueService.getEventPerDay(options);
      }
      this.processing = false;
      if (res.error) {
        return this.hideWaitAboveAll();
      }
      this.specificData = res.dados.map((data) => {
        const value = parseFloat(data.vendaBruta)
          ? (data.receita / data.vendaBruta) * 100
          : 0;
        return { ...data, margem: value };
      });
      return this.hideWaitAboveAll();
    },
    async filterBySpecificCompanie() {
      this.showDateOnEvent = true;
      this.specificLabels[0].hidden = false;
      this.specificLabels[1].hidden = false;
      const options = this.formatOptionsToQuery();
      if (!options) return this.hideWaitAboveAll();
      const res = await revenueService.getCompaniePerDay(options);
      this.processing = false;
      if (res.error) {
        return this.hideWaitAboveAll();
      }

      this.specificData = res.dados.map((data) => {
        const value = parseFloat(data.vendaBruta)
          ? (data.receita / data.vendaBruta) * 100
          : 0;
        return { ...data, margem: value };
      });
      return this.hideWaitAboveAll();
    },
    async customDateConfirmed() {
      if (this.customDate.length > 1 && this.customDate[0]) {
        if (moment(this.customDate[0]).isValid()) {
          this.components.datepicker.startDate = moment(
            this.customDate[0]
          ).format("YYYY-MM-DD");
        }
        if (moment(this.customDate[1]).isValid()) {
          this.components.datepicker.endDate = moment(
            this.customDate[1]
          ).format("YYYY-MM-DD");
        }
        await this.selectionUpdated();
      }
    },
    getBaseIdByText() {
      const base = this.activeBases.find(
        (base) => base.text === this.baseSelected
      );
      return base ? base.id : null;
    },
    async selectionUpdated() {
      this.sortMethod = {
        label: "",
        value: "default",
        isToSort: true,
        sortOrder: "none"
      };
      this.generalData = [];
      this.specificData = [];
      this.generalTotal = {};
      this.specificEvents = [];
      this.showWaitAboveAll();
      this.showingEventDayDetail = false;
      this.specificLabels.forEach((label) => {
        if (
          [
            "dia",
            "cliente",
            "cpf",
            "email",
            "ddd",
            "telefone",
            "usuario",
          ].includes(label.value)
        ) {
          label.hidden = true;
        }
      });
      await new Promise((resolve) => setTimeout(resolve, 300));
      if (this.companieSelected) {
        return this.filterBySpecificCompanie();
      }
      this.specificLabels[0].hidden = true;
      this.specificLabels[1].hidden = true;
      this.showDateOnEvent = false;
      const options = this.formatOptionsToQuery();
      if (!options) return this.hideWaitAboveAll();
      if (
        this.baseSelected &&
        this.baseSelected !== "todos" &&
        this.getBaseIdByText()
      ) {
        const returnedEvents = await eventService.select2(
          this.getLoggedId(),
          this.getBaseIdByText()
        );
        if (returnedEvents && returnedEvents.body) {
          this.specificEvents = returnedEvents.body
            .filter((evt) => evt.hasshowyet)
            .map((event) => ({
              value: event.ds_evento.trim(),
              text: event.ds_evento,
            }))
            .sort((a, b) => a.text.localeCompare(b.text));
        }
      }
      if (!options.dateStart || (!this.baseSelected && !this.eventSelected))
        return this.hideWaitAboveAll();
      const res = await revenueService.getDashboardData(options);
      this.processing = false;
      if (res.error) {
        return this.hideWaitAboveAll();
      }
      if (this.baseSelected && !this.eventSelected) {
        if (res.total) res.total.vendaTotal = 0;
        this.generalData = res.dados.map((data) => {
          const percentageAcessos2 = parseFloat(data.percentageAcessos2)
            ? parseFloat(data.percentageAcessos2) * 100
            : 0;
          const percentageAcessos = parseFloat(data.percentageAcessos)
            ? parseFloat(data.percentageAcessos) * 100
            : 0;
          res.total.vendaTotal +=  data.vendaTotal ? parseFloat(data.vendaTotal) : 0;
          return { ...data, percentageAcessos, percentageAcessos2 };
        });
        this.generalTotal = {
          ...(res.total || {}),
          dia: "TOTAL",
          percentageAcessos: parseFloat(res.total.percentageAcessos) * 100,
          percentageAcessos2: parseFloat(res.total.percentageAcessos2) * 100,
        };
      } else {
        this.specificData = res.dados.map((data) => {
          const value = parseFloat(data.vendaBruta)
            ? (data.receita / data.vendaBruta) * 100
            : 0;
          return { ...data, margem: value };
        });
      }
      this.hideWaitAboveAll();
    },
  },
  data() {
    return {
      showDateOnEvent: false,
      showingEventDayDetail: false,
      processing: false,
      loading: false,
      tableKey: 0, 
      customDate: "",
      genereralLabels: [
        "DIA",
        "ACESSOS",
        "CADASTROS",
        "% Aces.",
        "PEDIDOS",
        "% Aces.",
        "%Cad.",
        "INGRESSOS",
        "VENDA TOTAL",
        "RECEITA TOTAL",
      ],
      specificLabels: [
        {
          label: "DIA",
          value: "dia",
          sortOrder: "asc",
          isToSort: true,
          hidden: true,
        },
        {
          label: "Empresa",
          value: "empresa",
          sortOrder: "asc",
          isToSort: true,
          hidden: true,
        },
        {
          label: "Cliente",
          value: "cliente",
          sortOrder: "asc",
          isToSort: true,
          hidden: true,
        },
        {
          label: "CPF",
          value: "cpf",
          sortOrder: "asc",
          isToSort: true,
          hidden: true,
        },
        {
          label: "Email",
          value: "email",
          sortOrder: "asc",
          isToSort: true,
          hidden: true,
        },
        {
          label: "DDD",
          value: "ddd",
          sortOrder: "asc",
          isToSort: true,
          hidden: true,
        },
        {
          label: "Telefone",
          value: "telefone",
          sortOrder: "asc",
          isToSort: true,
          hidden: true,
        },
        {
          label: "Usuario",
          value: "usuario",
          sortOrder: "asc",
          isToSort: true,
          hidden: true,
        },
        {
          label: "Evento/Produto",
          value: "eventOrProduct",
          sortOrder: "asc",
          isToSort: true,
        },
        {
          label: "Qtde Ingres",
          value: "qtdeIngressos",
          sortOrder: "asc",
          isToSort: true,
        },
        {
          label: "+ Venda Bruta",
          value: "vendaBruta",
          sortOrder: "asc",
          isToSort: true,
        },
        {
          label: "- Repasse",
          value: "repasse",
          sortOrder: "asc",
          isToSort: true,
        },
        {
          label: "+ Receita",
          value: "receita",
          sortOrder: "asc",
          isToSort: true,
        },
        {
          label: "% Margem",
          value: "margem",
          sortOrder: "asc",
          isToSort: true,
        },
      ],
      components: {
        money: {
          decimal: ".",
          thousands: "",
          //prefix: 'R$ ',
          //suffix: ' #',
          precision: 2,
          masked: false /* doesn't work with directive */,
        },
        datepicker: {
          id: 1,
          format: "DD/MM/YYYY",
          minNights: 0,
          maxNights: 0,
          hoveringTooltip: true,
          displayClearButton: true,
          startDate: new Date("2018-01-01"),
          endDate: "",
          startingDateValue: new Date(),
          ptBr: {
            night: "Dia",
            nights: "Dias",
            "day-names": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            "check-in": "Início",
            "check-out": "Fim",
            "month-names": [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro",
            ],
          },
        },
      },
      form: {
        show: false,
        amount: 0,
        selectedDate: {
          start: "",
          end: "",
        },
      },
      sortMethod: {
        label: "",
        value: "default",
        isToSort: true,
        sortOrder: "none",
      },
      baseSelected: null,
      eventSelected: null,
      companieSelected: null,
      activeBases: [
        {
          value: null,
          text: "Selecione uma base",
        },
      ],
      filteredEvents: [
        {
          value: null,
          text: "Selecione um evento",
        },
      ],
      specificEvents: [],
      companies: [],
      eventsKey: 0,
      selects: {
        base: [],
        events: [],
        days: [],
        hours: [],
      },
      generalTotal: {
        acessos: 0,
        cadastros: 0,
        percentageAcessos: 0,
        pedidos: 0,
        percentageAcessos2: 0,
        percentageCadastros: 0,
        ingressos: 0,
        vendaTotal: 0,
        receitaTotal: 0,
      },
      generalData: [],
      specificData: [],
    };
  },
};
</script>
<style lang="scss">
.report-revenues {
  .mx-input:focus,
  .mx-input:focus-visible,
  .mx-input:hover,
  .revenue-selects:focus,
  .revenue-selects:focus-visible {
    outline: none;
    border: 1px solid #ccc !important;
  }

  .mx-input,
  .revenue-selects {
    cursor: pointer;
  }

  .mx-input::placeholder {
    color: #555;
  }

  .item-date-picker-revenue {
    .mx-input-wrapper,
    .mx-input {
      height: 100%;
    }
  }

  .revenue-selects {
    border: 1px solid #ccc;
    color: #555;
    min-width: 240px;
  }
}

.report-revenues th {
  background: white;
  position: sticky;
  top: 37px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  color: #504848;
}

.report-revenues .fixed-top-revenue {
  background: white;
  position: sticky;
  top: 0px;
  z-index: 999 !important;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.report-revenues tfoot td {
  background: white;
  position: sticky;
  bottom: 0;
}
.fixedTableLabel {
  max-width: 313px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
</style>