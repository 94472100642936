import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const revenueService = {
    getDashboardData,
    getCompaniePerDay,
    getEventPerDay,
    getDiaDetalhado
}

function getDiaDetalhado({ loggedId, dateStart, dateEnd, base, event, empresa }) {
    let url = config.api + `/v1/dashboard/getDiaDetalhado?loggedId=${loggedId}&dtInicial=${dateStart}&dtFinal=${dateEnd}&base=${base || ''}&evento=${event || ''}&empresa=${empresa || '' }`;
    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}

function getDashboardData({ loggedId, dateStart, dateEnd, base, event }) {
    let url = config.api + `/v1/dashboard/reportFinance?loggedId=${loggedId}&dtInicial=${dateStart}&dtFinal=${dateEnd}&base=${base || ''}&evento=${event || ''}`;
    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}

function getEventPerDay ({ loggedId, dateStart, dateEnd, base, event }) {
    let url = config.api + `/v1/dashboard/getEventosPorDia?loggedId=${loggedId}&dtInicial=${dateStart}&dtFinal=${dateEnd}&base=${base || ''}&evento=${event || ''}`;
    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}

function getCompaniePerDay ({ loggedId, dateStart, dateEnd, base, event, empresa }) {
    let url = config.api + `/v1/dashboard/getEmpresasPorDia?loggedId=${loggedId}&dtInicial=${dateStart}&dtFinal=${dateEnd}&base=${base || 'todos'}&evento=${event || 'todos'}&empresa=${empresa || 'todos' }`;
    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}